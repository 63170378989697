<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-3 bg-white rounded">
        <div class="row m-0 px-3 pt-3">
          <div class="col-12 col-xl-6 mx-0 mb-2 mb-xl-0">
            <p class="font-1-2s font-600">Edit Attribute</p>
          </div>
          <div class="col-12 col-xl-6 text-right ">
            <button
              type="button"
              class="btn btn-sm bt-main  px-3"
              @click="goto()"
            >
              Back
            </button>
          </div>
        </div>
        <div class="row m-0 px-3">
          <div class="col-12">
            <p class=" font-1-2s font-600 text-main">{{ AttrName }}</p>
          </div>
          <div class="col-12">
            <b-tabs v-model="tabIndex" content-class="mt-1 p-3">
              <b-tab title="Properties" :title-link-class="linkClass(0)" active>
                <AddUpdateAttribute
                  @returnAttrName="
                    (e) => {
                      AttrName = e;
                    }
                  "
                />
              </b-tab>
              <b-tab title="Options" :title-link-class="linkClass(1)">
                <AttributesOption
                  :key="$route.query.attr"
                  :attrId="$route.query.attr"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AttributesOption from "@/components/Manage/Attributes/AttributesOption.vue";
import AddUpdateAttribute from "@/components/Manage/Attributes/AddUpdateAttribute.vue";
export default {
  name: "AttributesEdit",
  components: { AttributesOption, AddUpdateAttribute },
  data() {
    return {
      tabIndex: 0,
      AttrName: "",
    };
  },
  watch: {},
  mounted() {},
  methods: {
    async goto() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
      );
      if (confirm) {
        if (!!this.$route.query?.back) {
          this.$router.push({
            name: atob(this.$route.query?.back),
            query: {
              attrGroupId: this.$route.query?.attrGroupId,
            },
          });
        } else {
          this.$router.push({ name: "Attributes" });
        }
      }
    },

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-main", "font-weight-bold"];
      } else {
        return ["text-muted"];
      }
    },
  },
};
</script>

<style></style>
