<template>
  <div class="row m-0 p-0">
    <div class="col-12 m-0 p-0  mb-5  ">
      <!-- ------------------------ -->

      <div class="row m-0 px-3">
        <div class="col-12 col-xl-7">
          <div class="form-group">
            <label>Attribute Name</label>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="data.attrname"
            />
          </div>
        </div>
        <div class="col-12 mx-0 mt-3 px-3">
          <p class="font-main font-600">Label Translation</p>
          <hr />
        </div>
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <label for="exampleInputEmail1">Label Thai</label>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="data.attrlabelTH"
            />
            <label class="mt-3" for="exampleInputEmail1"
              >Description Thai</label
            >
            <textarea
              class="form-control"
              rows="2"
              required="required"
              v-model="data.attrDescTH"
              maxlength="200"
            ></textarea>
            <label class="mt-3" for="exampleInputEmail1"
              >Link  Thai</label
            >
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="data.attrLinkTH"
            />
          </div>
        </div>
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <label for="exampleInputEmail1">Label English</label>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="data.attrlabelEN"
            />
            <label class="mt-3" for="exampleInputEmail1"
              >Description English</label
            >
            <textarea
              class="form-control "
              rows="2"
              required="required"
              v-model="data.attrDescEN"
              maxlength="200"
            ></textarea>
            <label class="mt-3" for="exampleInputEmail1"
              >Link English </label
            >
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="data.attrLinkEN"
            />
          </div>
        </div>
        <div class="col-12 col-xl-7 mb-2">
          <b-form-checkbox
            v-model="data.required"
            name="check-button"
            switch
            value="1"
          >
            Required
          </b-form-checkbox>
        </div>
        <div class="col-12 col-xl-7 mb-2">
          <b-form-checkbox
            v-model="data.active"
            name="check-button"
            switch
            value="1"
          >
            Active
          </b-form-checkbox>
        </div>
        <!-- ----------------------------- -->
        <div class="col-12 mx-0 mt-3 px-3">
          <p class="font-main font-600">Attribute Filter</p>
          <hr />
          <!-- ---------------------- -->
          <!-- <div
>>>>>>> release/sum2023-05-29
            class="form-group form-check"
            v-for="(e, i) in option_filter"
            :key="i"
          >
            <input

              type="radio"
>>>>>>> release/sum2023-05-29
              class="form-check-input"
              :id="e.filterName"
              :value="e.filterKey"
              v-model="data.filterTypelist"
            />
            <label class="form-check-label" :for="e.filterName">
              {{ `${e.filterKey} ${e.filterName}` }}</label
            >
          </div> -->
          <!-- {{ data.filterTypeSpecial }} -->
          <div
            class="form-check mb-2"
            v-for="(e, i) in option_filterSpecial"
            :key="i"
          >
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              :id="e.filterName"
              :value="e.filterKey"
              v-model="data.filterTypeSpecial"
            />
            <label class="form-check-label" :for="e.filterName">
              {{ `${e.filterName}` }}
            </label>
          </div>
          <button
            type="button"
            class="btn btn-sm btn-light  px-3"
            @click="Reset()"
          >
            Reset
          </button>
          <hr />
          <!-- {{ data.filterTypeUsage }} -->
          <div
            class="form-check mb-2"
            v-for="(e, i) in option_filterUsage"
            :key="i"
          >
            <input
              :disabled="
                data.filterTypeSpecial == 'Q' ||
                  data.filterTypeSpecial == 'X' ||
                  data.filterTypeSpecial == 'M'
              "
              class="form-check-input"
              type="checkbox"
              :id="i"
              :value="e.filterKey"
              v-model="data.filterTypeUsage"
            />
            <label class="form-check-label" :for="i">{{
              `${e.filterName}`
            }}</label>
          </div>
        </div>
        <!-- ------------------------- -->
        <div class="col-12 mx-0 mt-4 mb-3 px-3 ">
          <button
            type="button"
            class="btn btn-sm bt-main  px-3"
            @click="save()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../common/Button.vue";
export default {
  components: { Button },

  name: "AddUpdateAttribute",

  data() {
    return {
      reModalcatcode: Date.now() + 5,
      mode_edit: false,
      data: {
        attrId: "",
        attrname: "",
        attrlabelTH: "",
        attrlabelEN: "",
        attrDescTH: "",
        attrDescEN: "",
        attrLinkTH: "",
        attrLinkEN: "",
        filterType: "",
        filterTypeSpecial: "",
        filterTypeUsage: [],
        required: 1,
        active: 1,
      },
      option_filterSpecial: [],
      option_filterUsage: [],
    };
  },
  watch: {
    "data.active": function(v) {
      if (!v) {
        this.data.active = 0;
      }
    },
    "data.required": function(v) {
      if (!v) {
        this.data.required = 0;
      }
    },

    "data.filterTypeSpecial": function(v) {
      this.data.filterTypeUsage = [];
    },
  },
  mounted() {
    this.getdata_list();
    this.get_attribute_filter();
  },
  methods: {
    async getdata_list() {
      try {
        if (!!this.$route.query?.attr) {
          let data = { attrGroupId: "", attrId: this.$route.query?.attr };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/get_attribute`,
            data,
            1
          );

          let {
            attrlabelEN,
            attrlabelTH,
            attrname,
            attrId,
            active,
            filterType,
            required,
            attrDescTH,
            attrDescEN,
            attrLinkTH,
            attrLinkEN
          } = getAPI.data[0];

          this.data.attrname = attrname;
          this.data.attrlabelEN = attrlabelEN;
          this.data.attrlabelTH = attrlabelTH;
          this.data.attrDescTH = attrDescTH;
          this.data.attrDescEN = attrDescEN;
          this.data.attrLinkTH = attrLinkTH;
          this.data.attrLinkEN = attrLinkEN;
          this.data.required = required;
          this.data.attrId = attrId;
          this.data.active = active;

          if (filterType == "Q" || filterType == "X" || filterType == "M") {
            this.data.filterTypeSpecial = filterType;
          } else {
            this.data.filterTypeUsage = filterType.split("|");
          }

          this.$emit("returnAttrName", attrname);
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async get_attribute_filter() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute_filter_type`,
          {},
          1
        );

        if (getAPI.data.length > 0) {
          for (var i in getAPI.data) {
            if (getAPI.data[i].filterType === "S") {
              this.option_filterSpecial.push(getAPI.data[i]);
            } else {
              this.option_filterUsage.push(getAPI.data[i]);
            }
          }
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },

    async goto() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
      );
      if (confirm) {
        this.$router.push({ name: "Attributes" });
      }
    },

    async validate() {
      return new Promise(async (resolve, reject) => {
        let { attrname, attrlabelTH, attrlabelEN } = this.data;
        if (!!!attrname) {
          resolve(`Please enter Attribute Name / กรุณาระบุ Attribute Name`);
        }
        if (!!!attrlabelTH) {
          resolve(`Please enter labelTH / กรุณาระบุ labelTH`);
        }
        if (!!!attrlabelEN) {
          resolve(`Please enter labelEN / กรุณาระบุ labelEN`);
        }
        resolve("OK");
      });
    },
    async save() {
      try {
        let vali = await this.validate();

        if (vali !== "OK") {
          throw vali;
        }
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          this.data.filterType = String(this.data.filterTypeSpecial).trim();
          if (this.data.filterType === "") {
            this.data.filterType = String(this.data.filterTypeUsage).replace(
              /,/g,
              "|"
            );
          }

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/add_attribute`,
            this.data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          if (!!this.$route.query?.attr) {
            this.getdata_list();
          } else {
            this.$router.push({ name: "Attributes" });
          }
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async Reset() {
      this.data.filterTypeSpecial = "";
    },
  },
  // async beforeRouteLeave(to, from, next) {
  //   // const answer = window.confirm(
  //   //   "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
  //   // );
  //   let confirm = await this.$serviceMain.showConfirmAlert(
  //     this,
  //     "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
  //   );
  //   if (confirm) {
  //     next();
  //   } else {
  //     next(false);
  //   }
  // },
};
</script>

<style></style>
