<template>
  <button
    type="button"
    class="btn ml-0"
    :class="`bt-${$store.getters['serviceActions/get_bu']}`"
  >
    {{ btName }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: ["btName"],
};
</script>

<style>
</style>