<template>
  <b-modal
    id="ModalAddAttributeOption"
    hide-footer
    no-close-on-backdrop
    title="Add Attributes Option"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Label Thai</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="data.labelTH"
            :disabled="data.attrId === ''"
          />
        </div>
        <!-- ------------------------- -->
        <div class="form-group">
          <label>Label English</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="data.labelEN"
            :disabled="data.attrId === ''"
          />
        </div>
        <b-form-checkbox
          v-model="data.active"
          name="check-button"
          switch
          value="1"
          :disabled="data.attrId === ''"
        >
          Active
        </b-form-checkbox>
      </div>

      <div class="col-12 mt-2 text-right">
        <button class="btn bt-main1 mx-1 px-4" @click="hide()">ยกเลิก</button>
        <button
          class="btn bt-main mx-1 px-4"
          @click="confirm()"
          :disabled="data.attrId === ''"
        >
          ยืนยัน
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalAddAttributeOption",

  data() {
    return {
      data: { labelEN: "", labelTH: "", id: "", active: 1, attrId: "" },
    };
  },
  watch: {
    "data.active": function(v) {
      if (!v) {
        this.data.active = 0;
      }
    },
  },
  computed: {},
  mounted() {
    this.init_data();
  },
  methods: {
    init_data() {
      if (!!this.$route.query?.attr) {
        this.data.attrId = this.$route.query?.attr;
      }
    },
    async confirm() {
      try {
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/add_attribute_option`,
            this.data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.$emit("get_data");
          this.hide();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },

    hide() {
      this.$bvModal.hide("ModalAddAttributeOption");
    },
  },
};
</script>

<style scoped>
.cat-height {
  height: 55vh;
  overflow-y: auto;
}
</style>
