<template>
  <div class="row m-0 p-0">
    <div class="col-12 col-xl-7 px-2">
      <!-- ------------------- sort ------------------------ -->
      <div class="row m-0 p-0 ">
        <div class="col-9 m-0 p-0">
          <p class="font-main font-600 m-0 p-0">Option</p>
        </div>
        <div class="col-3 m-0 p-0 text-center">
          <button
            type="button"
            class="btn btn-sm m-0 p-0  bt-main px-4  "
            @click="CallModalAddAttributeOption()"
          >
            <b-icon icon="plus-circle-fill"></b-icon>
            Add
          </button>
        </div>
        <div class="col-12 m-0 p-0">
          <hr />
        </div>
        <!-- --------------------- -->
        <div class="col-12 m-0 p-0">
          <div class="row m-0 p-0">
            <div class="col-12 m-0 p-0 col-xl-6 mb-3">
              <div class="input-group input-group-sm   ">
                <div class="input-group-prepend input-group-sm">
                  <select class="custom-select" v-model="mode">
                    <option
                      v-for="(e, i) in select_mode"
                      :key="i"
                      :value="e.key"
                    >
                      {{ e.name }}
                    </option>
                  </select>
                </div>
                <input
                  type="search"
                  class="form-control form-control-sm"
                  placeholder="ค้นหา / Seach"
                  v-model="find"
                  v-on:keyup.enter="Search()"
                />
                <div class="input-group-append">
                  <button class="btn btn-sm bt-main" @click="Search()">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
            <!-- --------------------------- -->
            <div class="col-12 m-0 p-0 col-xl-6 text-right">
              <span style="font-weight: bold;">
                <span class="text-main">{{
                  new Intl.NumberFormat().format(totalRows)
                }}</span>
                &nbsp;รายการ
              </span>
              <button
                type="button"
                class="btn btn-sm  bt-bnb  px-3 ml-3"
                @click="saveSort()"
              >
                <b-icon icon="sort-alpha-up"></b-icon>
                Save Sort
              </button>
            </div>
          </div>
        </div>
        <!-- ------------------------ -->
        <div class="col-12 m-0 p-0 ">
          <div class="row m-01 pb-2  ">
            <div class="col-2 m-0 p-0 "></div>
            <div
              class="col-5  m-0 p-0 font-600"
              type="button"
              @click="sort_value()"
            >
              Label
              <b-icon
                :icon="sortOrder === false ? 'arrow-up' : 'arrow-down'"
              ></b-icon>
            </div>
            <div class="col-5  m-0 p-0 font-600">Active</div>
            <div class="col-12 m-0">
              <hr class="mb-0 pb-0" />
            </div>
          </div>
          <div class="col-12 m-0 p-0" style="height:65vh;overflow-y:auto;">
            <draggable
              handle=".handle"
              v-model="attributb_list"
              class="row m-0 p-0 "
              :move="checkMove"
              @start="drag = true"
              @end="drag = false"
              @change="change"
              v-bind="dragOptions"
            
            >
              <div
                class="col-12  m-0 px-0 py-3 border-bottom"
                v-for="(e, i) in attributb_list"
                :key="i"
                style="font-size:0.8rem;"
                :class="[e.id === data_edit.id ? 'select_option_att' : '']"
                @click="select_lable_option(e)"
              >
                <div class="row m-0 p-0  " type="button">
                  <div class="col-2 handle ">
                    <b-icon icon="arrows-move" type="button" class=""></b-icon>
                  </div>
                  <div class="col-5 m-0 p-0 ">{{ e.labelTH }}</div>
                  <div class="col-5 m-0 p-0 ">
                    <span
                      class="badge"
                      :class="
                        String(e.active) === '1' ? 'badge-main' : 'badge-twd'
                      "
                      >{{ String(e.active) === "1" ? "Yes" : "No" }}</span
                    >
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>

      <hr />
    </div>
    <div class="col-12 col-xl-5 px-2">
      <!-- ----------------------- edit -------------------- -->
      <p class="font-main  font-600">Label Option</p>
      <hr class=" " />
      <div class="row m-0 p-3 border-input" v-if="attributb_list.length > 0">
        <div class="col-12">
          <div class="form-group">
            <label>Label Thailand</label>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="data_edit.labelTH"
            />
          </div>
          <!-- ------------------------- -->
          <div class="form-group">
            <label>Label English</label>
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="data_edit.labelEN"
            />
          </div>
          <b-form-checkbox
            v-model="data_edit.active"
            name="check-button"
            switch
            value="1"
          >
            Active
          </b-form-checkbox>
        </div>
        <div class="col-12 text-right">
          <button
            type="button"
            class="btn btn-sm bt-bnb "
            @click="save_lable_option()"
          >
            Save Option
          </button>
        </div>
      </div>
    </div>
    <ModalAddAttributeOption
      :key="reModalAddAttributeOption"
      @get_data="get_data"
    />
  </div>
</template>

<script>
import ModalAddAttributeOption from "@/components/Manage/Attributes/ModalAddAttributeOption.vue";
import draggable from "vuedraggable";
export default {
  name: "AttributesOption",
  components: {
    draggable,
    ModalAddAttributeOption,
  },
  data() {
    return {
      mode: "labelTH",
      select_mode: [{ name: "ค้นหาด้วย labelTH", key: "labelTH" }],
      totalRows: 0,
      find: "",
      reModalAddAttributeOption: Date.now() + 5,
      data: { attrId: "" },
      data_edit: { labelEN: "", labelTH: "", id: "", active: 1 },
      attributb_list: [],
      attributb_lists: [],
      sortOrder: true,
    };
  },
  watch: {
    "data_edit.active": function(v) {
      if (!v) {
        this.data_edit.active = 0;
      }
    },
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  mounted() {
    this.get_data();
  },
  methods: {
    sort_value() {
      const sortedByNameAsc = this.attributb_list.sort((a, b) =>
        a.labelTH.localeCompare(b.labelTH)
      );
      const sortedByNameDesc = sortedByNameAsc.slice().reverse();
      this.sortOrder = !this.sortOrder;
      this.sortOrder === true
        ? (this.attributb_list = sortedByNameAsc)
        : (this.attributb_list = sortedByNameDesc);
      this.change();
    },
    unSearch() {
      this.attributb_list = this.attributb_lists;
      this.totalRows = this.attributb_list.length;
      this.select_lable_option(this.attributb_list[0]);
    },
    Search() {
      var results = [];
      if (this.find == "") {
        // this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.attributb_lists;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.attributb_list = results;
        this.totalRows = this.attributb_list.length;
        this.select_lable_option(this.attributb_list[0]);
      }
    },
    CallModalAddAttributeOption() {
      this.reModalAddAttributeOption = Date.now() + 5;
      setTimeout(() => {
        this.$bvModal.show("ModalAddAttributeOption");
      }, 100);
    },
    async save_lable_option() {
      try {
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/add_attribute_option`,
            { ...this.data_edit, attrId: this.$route.query?.attr },
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.get_data();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    select_lable_option(option) {
      let { active, id, labelEN, labelTH } = option;
      this.data_edit.active = active;
      this.data_edit.id = id;
      this.data_edit.labelEN = labelEN;
      this.data_edit.labelTH = labelTH;
    },
    async saveSort() {
      try {
        console.log(this.attributb_list);
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/sort_attribute_option`,
            this.attributb_list,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.get_data();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    change() {
      console.log(this.attributb_list);
      for (var i in this.attributb_list) {
        this.attributb_list[i].sortBy = i;
      }
    },
    checkMove(e) {
      //   return this.isDraggable(e.draggedContext);
    },
    isDraggable(context) {
      const { index, futureIndex } = context;
      return !(
        this.data.fileUploads[index].fixed ||
        this.data.fileUploads[futureIndex].fixed
      );
    },
    async get_data() {
      try {
        if (!!this.$route.query?.attr) {
          let data = { attrId: this.$route.query?.attr };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/get_attribute_option`,
            data,
            1
          );

          let arr = getAPI.data;

          arr.forEach((object) => {
            object["attrId"] = this.$route.query?.attr;
          });
          this.attributb_list = arr;
          this.attributb_lists = arr;
          // console.log(this.attributb_list);
          this.totalRows = this.attributb_lists.length;
          this.data.attrId = this.$route.query?.attr;
          if (getAPI.data.length > 0) {
            this.select_lable_option(getAPI.data[0]);
          }
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style scoped>
.border-b {
  border-bottom: 2px solid rgb(234, 234, 234);
}

.select_option_att {
  background: #dee2e6;
}

.border-input {
  background: #dee2e6;
  border-radius: 8px;
}
</style>
